import color from 'color'

const HeadBase = props => {
	const [ skinColor, lightenedColor ] = props.skin

	return <svg xmlns="http://www.w3.org/2000/svg" width="120" height="127" viewBox="0 0 120 127">
		<defs>
			<linearGradient id="head-a" x1="61.86" y1="40.87" x2="58.62" y2="111.36" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color={skinColor}/>
				<stop offset="1" stop-color={lightenedColor}/>
			</linearGradient>
			<linearGradient id="head-b" x1="18120.56" y1="9942.02" x2="18094.18" y2="9930.6" gradientTransform="matrix(0.96, -0.28, 0.28, 0.96, -20113.37, -4402.14)" gradientUnits="userSpaceOnUse">
				<stop offset="0"/>
				<stop offset="1" stop-color="#0066f8"/>
			</linearGradient>

			<linearGradient id="head-c" x1="19247.49" y1="10360.05" x2="19221.1" y2="10348.63" gradientTransform="matrix(-0.91, -0.41, -0.41, 0.91, 21888.18, -1389.37)" gradientUnits="userSpaceOnUse">
				<stop offset="0"/>
				<stop offset="1" stop-color="#0066f8"/>
			</linearGradient>

			<linearGradient id="head-d" x1="5" y1="2" x2="0" y2="0">
				<stop offset=".9" stop-color={skinColor}/>
				<stop offset="1" stop-color={lightenedColor}/>
			</linearGradient>
		</defs>
		<path d="M66.27,28.14c-17.9-1.63-33.47,13.14-35.22,32.5a89.67,89.67,0,0,0,.39,19.08,5.68,5.68,0,1,0,2.15,10.75c3.62,12.8,11.08,23.76,24.78,25,24.49,2.23,35-29.66,36.72-49S84.16,29.77,66.27,28.14Z" style={{ fill: 'url(#head-a)' }} />
		<ellipse cx="83.34" cy="83.27" rx="2.36" ry="2.37"/>
		<ellipse cx="57.44" cy="80.92" rx="2.36" ry="2.37"/>
		<path d="M56.86,93.58A6.16,6.16,0,1,0,69.13,94.7Z" fill="#fff" />
		<path d="M63.86,70.7c-.85-1.8-5-1.25-6-1.12-2.84.37-5.51,1.58-5.41,2.07s2.21-.32,5.62.35c3,.58,4.38,1.73,5.35,1A2,2,0,0,0,63.86,70.7Z" style={{ fill: 'url(#head-b)' }}/>
		<path d="M80.26,71.89c1.09-1.65,5.11-.52,6.12-.24,2.75.77,5.23,2.35,5.05,2.83s-2.13-.64-5.61-.47c-3,.15-4.58,1.09-5.43.19A2,2,0,0,1,80.26,71.89Z" style={{ fill: 'url(#head-c)' }}/>
		<path d="M72.37,86.44C72.08,89,72,90.23,72.74,91a3.26,3.26,0,0,0,4.16-.29c2.08-2.35-1.73-9.42-3.22-9.16C72.93,81.64,72.7,83.61,72.37,86.44Z" style={{ fill: 'url(#head-d)' }}/>
	</svg>
}

const FemaleHead = props => {
	const [ skinColor, lightenedColor ] = props.skin
	return <svg xmlns="http://www.w3.org/2000/svg" width="275.07" height="220.98" viewBox="0 0 275.07 220.98">
		<defs>
			<linearGradient id="fh-a" x1="141.05" y1="90.98" x2="134.76" y2="240.95" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color={skinColor}/>
				<stop offset="1" stop-color={lightenedColor}/>
			</linearGradient>
			<linearGradient id="fh-b" x1="147.26" y1="110.75" x2="164.38" y2="112.08">
				<stop offset="0" stop-color={skinColor} />
				<stop offset="1" stop-color={lightenedColor}/>
			</linearGradient>
			<linearGradient id="fh-c" x1="110.72" y1="89.71" x2="104.44" y2="239.67">
				<stop offset="0" stop-color={skinColor} />
				<stop offset="1" stop-color={lightenedColor}/>
			</linearGradient>
		</defs>
		<path d="M107.44,96.7c1.16,20,11,53.42,36.5,51.92,24.56-1.44,31.38-35.86,30.22-55.84S157.19,57,138.66,58,106.27,76.72,107.44,96.7Z" style={{fill: 'url(#fh-a)'}}/>
		<path d="M164.3,104.16a2.52,2.52,0,1,0,2.66,2.37A2.53,2.53,0,0,0,164.3,104.16Z"/>
		<path d="M140,110.65a2.53,2.53,0,1,0-2.67-2.38A2.54,2.54,0,0,0,140,110.65Z"/>
		<path d="M156.32,113.91c0,2-3.49,4.35-5.25,3.45-.49-.25-1-.83-1.32-3.06-.74-4.52,0-9.67,1.06-9.71.86,0,.55,3.37,3.58,6.78C155.53,112.65,156.3,113,156.32,113.91Z" style={{fill: color(skinColor).darken(0.1).hex() }} />
		<path d="M153.68,122.5a5.66,5.66,0,1,1-11.31,0" style={{fill: '#fff'}}/>
		<path d="M168.12,100.17a.42.42,0,0,1-.29,0,8.78,8.78,0,0,0-6.89-.39.4.4,0,0,1-.51-.24.41.41,0,0,1,.24-.51,9.68,9.68,0,0,1,7.51.42.39.39,0,0,1,.18.53A.38.38,0,0,1,168.12,100.17Z" fill="#231f20"/>
		<path d="M136.14,100.17a.39.39,0,0,0,.28,0,9,9,0,0,1,4.18-.91,9.16,9.16,0,0,1,2.72.52.4.4,0,0,0,.26-.75,9.65,9.65,0,0,0-7.5.42.4.4,0,0,0,.06.74Z" fill="#231f20" />
		<ellipse cx="109.95" cy="108.02" rx="4.03" ry="4.04" style={{fill: 'url(#fh-c)'}}/>
	</svg>
}

const Head = props => {
	const { top, left, body, skin } = props 

	return <div style={{position: 'absolute', top: body === 'male' ? 0 : 0 , left: body === 'male' ? 130 : 35}}>
		{body === 'male' 
			? <HeadBase skin={skin} />
			: <FemaleHead skin={skin} />}
	</div>
}

export default Head