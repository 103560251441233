import { useState, useContext, useEffect } from 'react'
import { useLoader } from '../utils/loader'
import color from 'color'
import SpeechBubble from './SpeechBubble'
import SourceCarousel from './SourceCarousel'
import Palette from './Palette'
import Body from './body'


import { XF_PURPLE } from '../colors'


function downloadBlob(blob, name = 'file.txt') {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = name;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent('click', { 
      bubbles: true, 
      cancelable: true, 
      view: window 
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

const CharacterBuilder = props => {
  const { sources } = props
  const { loaded } = useLoader()

  const [ showBubble, setShowBubble ] = useState(true)
  const [ body, setBodyType ] = useState('male')
  const [ name, setName ] = useState('')
  const [ selected, setSelected ] = useState({
  	"tops": sources[body + "-tops"][0],
  	"legs": sources[body + "-legs"][0],
  	"shoes": sources[body + "-shoes"][0],
    "hair": sources[body + "-hair"][0],
    "headgear": sources[body + "-headgear"][0],
    "accessories": sources[body + "-accessories"][0],
  })

  const [ skinColor, setSkinColor ] = useState('#811708')
  const [ hairColor, setHairColor ] = useState('#510402')

  const [ accessoryMode, setAccessoryMode ] = useState('tops')
  const [ personalizeMode, setPersonalizeMode ] = useState('skin')

  useEffect(() => {
  	setSelected({
	  	"tops": sources[body + "-tops"][0],
	  	"legs": sources[body + "-legs"][0],
	  	"shoes": sources[body + "-shoes"][0],
      "hair": sources[body + "-hair"][0],
      "headgear": sources[body + "-headgear"][0],
      "accessories": sources[body + "-accessories"][0],
	  })
  }, [body])

  const hairGradient = [
		hairColor, 
		color(hairColor).darken(0.75).saturate(1).hex()
	]

  const onSourceSelect = (source) => {
    console.log(accessoryMode, source)
  	setSelected({
  		...selected,
  		[accessoryMode]: source
  	})
  }

  const renderPersonalize = props => {
  	switch (personalizeMode) {
  		case 'skin': return <Palette key={personalizeMode} setColor={setSkinColor} palette={'/skin-palette.png'} />
  		case 'haircolor': return <Palette key={personalizeMode} setColor={setHairColor} palette={'/hair-palette.png'} />
  		default: return <Palette setColor={setSkinColor} palette={'/skin-palette.png'} />
  	}
  	
  }

  const toggleBody = () => {
  	setBodyType(body => body === 'male' ? 'female' : 'male')
  }

  const getUrl = () => {
    const params = {
      name,
      body,
      skinColor,
      hairColor,
      ...selected
    }

    const url = '?' + Object.entries(params).map(([key, value]) => key + '=' + encodeURIComponent(value)).join('&')
    console.log(params, url)
    return url
  }

  const downloadHero = async () => {
    const blob = fetch('/your-switch-squad-hero' + getUrl())
      .then(res => res.blob())
      .then( blob => {
         var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = "your-switch-squad-hero.png"
          document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
          a.click();    
          a.remove();  //afterwards we remove the element again    
      })
  }

  return !loaded ? false : <>
	<div style={{position: 'relative', display: 'flex', justifyContent: 'center', flexGrow: 1, paddingTop: '4rem'}}>
      <img src="/builder-bg.jpg" style={{position: 'absolute', top: '-8rem', width: 1600, opacity: 0.75}}/>
      <div className="meta-container">
      {showBubble && <SpeechBubble style={{width: 500, left: 390, top: 160}} onClick={() => setShowBubble(false)}>
        <p style={{fontSize: 30, marginTop: 0}}>Switch Squad assemble!</p>
        <p style={{fontSize: 18, margin: 0}}>There is cellular injustice in the world! People need your help switching from their current wireless provider to Xfinity Mobile. Join the Switch Squad today and start saving the mobile world by building your Switch Squad avatar. Don’t forget to share it with your team!</p>
      </SpeechBubble>}

     	<span style={{position: 'absolute', fontSize: 26}}>Hey, Xfinity teammate!<br/>Let’s build your Switch Squad hero!</span>
        <div className="body-container">
          <div className="arrow" style={{position: 'absolute', top: 250, left: 120, width: 20, height: 20, padding: 20, borderRadius: 50, background: '#fff', transformOrigin: 'center', zIndex: 99}} onClick={toggleBody}>
            <svg xmlns="http://www.w3.org/2000/svg" width="10.2" height="19.2" style={{position: 'relative', background: 'transparent', zIndex: 99, top: -10, left: -6}}>
      				<path fill="#000" stroke="#000" d="m2 10 8-9a1 1 0 0 0 0-1 1 1 0 0 0-1 0L0 9a1 1 0 0 0 0 1l9 9a1 1 0 0 0 1 0 1 1 0 0 0 0-1Z"/>
      		  </svg>
          </div>

          <Body {...selected} 
          	style={{top: 60}}
          	body={body}
          	skinColor={skinColor} 
          	hairGradient={hairGradient} />

          <div className="arrow" style={{position: 'absolute', top: 250, right: 70, width: 10, height: 20, padding: 20, borderRadius: 50, background: '#fff', transformOrigin: 'center'}} onClick={toggleBody}>
            <svg  xmlns="http://www.w3.org/2000/svg" width="10.2" height="19.2" style={{position: 'relative', top: -10, left: -4}}>
      				<path fill="#000" stroke="#000" d="m9 10-9 8a1 1 0 0 0 1 1l9-9a1 1 0 0 0 0-1L1 0a1 1 0 0 0-1 1Z" />
      		  </svg>
          </div>
        </div>
        <div className="controls-container">
          <div style={{borderBottom: '2px solid #e5e5e5', marginBottom: '2rem'}}>
            <div className="clothes-buttons">
              <div className={"clothes-option" + (accessoryMode === 'tops' ? ' active': '')} onClick={() => setAccessoryMode('tops')}>
                <div className="img-container" style={{padding: '3px 0'}}><img src="img/icons/shirt.svg" /></div>
                <div>Tops</div>
              </div>

              <div className={"clothes-option" + (accessoryMode === 'legs' ? ' active': '')} onClick={() => setAccessoryMode('legs')}>
                <div className="img-container" style={{padding: '2px 0'}}><img src="img/icons/pants.svg" /></div>
                <div>Bottoms</div>
              </div>

              <div className={"clothes-option" + (accessoryMode === 'shoes' ? ' active': '')} onClick={() => setAccessoryMode('shoes')}>
                <div className="img-container" style={{padding: '6px 0'}}><img src="img/icons/shoe.svg" /></div>
                <div>Footwear</div>
              </div>

            	<div className={"clothes-option" + (accessoryMode === 'hair' ? ' active': '')} onClick={() => setAccessoryMode('hair')}>
                <div className="img-container" style={{padding: '3px 0'}}><img src="img/icons/hairbrush.svg" /></div>
               	<div>Hairstyle</div>
             </div>

              <div className={"clothes-option" + (accessoryMode === 'accessories' ? ' active': '')} onClick={() => setAccessoryMode('accessories')}>
                <div className="img-container" style={{padding: '3px 0'}}><img src="img/icons/watch.svg" /></div>
                <div>Accessories</div>
             </div>

             <div className={"clothes-option" + (accessoryMode === 'headwear' ? ' active': '')} onClick={() => setAccessoryMode('headgear')}>
                <div className="img-container" style={{padding: '3px 0'}}><img src="img/icons/hat.svg" /></div>
                <div>Headwear</div>
             </div>

            </div>

            <SourceCarousel 
            	hairGradient={hairGradient}
            	type={accessoryMode}
            	sources={sources[body + '-' + accessoryMode]} 
            	onSelect={onSourceSelect} />
          </div>

          <div>
            <div className="clothes-buttons">
              <div className={"clothes-option" + (personalizeMode === 'skin' ? ' active': '')} onClick={() => setPersonalizeMode('skin')}>
                <div className="img-container" style={{padding: '6px 0'}}><img src="img/icons/color.svg" /></div>
                <div>Skin Tone</div>
              </div>

              <div className={"clothes-option" + (personalizeMode === 'haircolor' ? ' active': '')} onClick={() => setPersonalizeMode('haircolor')}>
                <div className="img-container" style={{padding: '0'}}><img src="img/icons/paintbrush.svg" /></div>
                <div>Hair Color</div>
              </div>
            </div>
          </div>

          <div className="palettes">
          	{renderPersonalize()}
          </div>
        </div>
      </div>

      <div style={{position: 'absolute', top: 730, display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
        <div style={{textAlign: 'center', margin: '10px 0 0 0'}}>
          What's your name? <br/><input onChange={evt => setName(evt.target.value)} type="text" style={{marginTop: 5, width: 300, padding: 20, border: 'none', borderRadius: 10}}/>
        </div>
        <div style={{marginTop: 20, textAlign: 'center', lineHeight: 1.5}}>Download your hero to see your avatar<br/>in action! Don't forget to share with your team!</div>
        <div className="download" style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 25, marginBottom: 50, background: 'black', padding: '10px 30px', borderRadius: 15, color: 'white'}} onClick={downloadHero}>
          <div className="img-container" style={{ margin: 10, width: 40, height: 25}}><img src="img/icons/save.svg" /></div>
          <div>Download</div>
        </div>
      </div>
    </div>
    <style jsx>{`
      .clothes-buttons {
        display: flex;
        justify-content: center;
      }

      .clothes-buttons .img-container {
        height: 50px;
        margin-bottom: 0.75rem;
        justify-content: center;
        display: flex;
      }

      .clothes-option {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 1rem;
        opacity: 0.5;
        transition: opacity 0.25s;
        cursor: pointer;
        font-weight: bold;
        font-size: 0.6rem;
      }

      .clothes-option.active, .clothes-option:hover {
        opacity: 1
      }

      .clothes-buttons img {
        object-position: center;
        object-fit: contain;
        width: 55%
        height: 100%;
      }

      @media (min-width: 1200px) {
        .clothes-option {
          font-size: 0.7rem;
        }

        .clothes-buttons img {
          width: 65%
        }
      }


      @media (min-width: 1200px) {
        .clothes-option {
          font-size: 1rem;
        }

        .clothes-buttons img {
          width: 100%
        }
      }


      .item-carousel { 
        min-height: 100px;
      }

      .meta-container {
        display: flex;
        flex-grow: 1;
        max-width: 1200px;
        position: relative;
      }

      .body-container {
      	position: relative;
      	display: flex;
        flex-grow: 1;
        flex-basis: 45%;
      }

      .body-container .arrow {
      	cursor: pointer;
      	transform-origin: center;
      	transition: opacity 0.25s;
      	width: 10px;
      	height: 20px;
      }

      .body-container .arrow:hover {
      	opacity: 0.5;
      }


      .controls-container {
        flex-grow: 1;
        flex-basis: 55%;
        background: white;
        border-top-left-radius: 50px;
        border-bottom-right-radius: 50px;
        padding: 25px;
        min-height: 200px;
        min-width: 200px;
      }

      .palettes {
      	padding: 25px;
      	display: flex;
      	align-items: center;
      	justify-content: center;
      }

      .download { 
        opacity: 1;
        transition: opacity 0.25s;
        cursor: pointer;
      }

      .download:hover { 
        opacity: 0.75;
      }
    `}</style>
  </>
}

export default CharacterBuilder