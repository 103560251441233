
const TorsoBase = props => {
	const [ skinColor, lightenedColor ] = props.skin

	return <svg xmlns="http://www.w3.org/2000/svg" width="368" height="370" viewBox="0 0 368 370">
		<defs>
			<linearGradient id="torso-a" x1="185.04" y1="88.77" x2="183.3" y2="275.75" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color={skinColor} />
				<stop offset="0.97" stop-color={lightenedColor} />
			</linearGradient>
		</defs>
		<path 
			d="M327.2,177.66a15.12,15.12,0,0,0-8-13.32l-43.15-36.28,0-.05-35-29.8a25.34,25.34,0,0,0-2.56-1.71l-.23-.19v.06A25.27,25.27,0,0,0,224.53,93a85.37,85.37,0,0,1-8.7-1.29c-1.43-.28-2.8-.6-4.13-.93l-1.55-.4-.88-.24-.61-.18c-.85-.24-1.67-.49-2.46-.74h0c-1-.31-1.88-.62-2.76-.94h0a48.19,48.19,0,0,1-.6-23.34l-23.17-2.37s-1.05,15.35-10.31,23.9c-4.89.95-9.94,2-15.13,3.31-1.41.34-2.8.69-4.18,1.06v0c-6.38,1.64-12.46,3.42-18.22,5.27a25,25,0,0,0-3-.19,24.09,24.09,0,0,0-20.88,12l-21,25.58-40.4,48.36a13,13,0,0,0,.1,21l-.09.09,83.82,58.39-2.63,29.24,113,1.89s1.34-9.59,2.66-25.08a11.26,11.26,0,0,0,7-6.68l71.28-71.28a15.39,15.39,0,0,0,2.21-2.21l.55-.55-.09-.07A15,15,0,0,0,327.2,177.66Zm-194.37,55.2-.94,10.44L78.21,192.84l31.13-25.53,8.22-6.83c2.61,13.21,6,26.72,10.54,38.33A76.37,76.37,0,0,1,132.83,232.86ZM245,242.67c1.23-24.85,1.45-56.15-1.92-87.09l10.82,5.82,0,.18,36.25,20.59Z" 
			style={{ fill: 'url(#torso-a)' }} />
	</svg>
}

const FemaleTorso = props => {

	const [ skinColor, lightenedColor ] = props.skin

	return <svg xmlns="http://www.w3.org/2000/svg" width="275.37" height="331.98" viewBox="0 0 275.37 331.98">
		<defs>
			<linearGradient id="female-torso-a" x1="129.25" y1="130" x2="134.5" y2="230.23" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color={lightenedColor} />
				<stop offset="0.5" stop-color={skinColor}/>
			</linearGradient>
		</defs>
		<path d="M224.51,197.65a11,11,0,0,0-1.35-8.41l-22.51-58.13-6.63-21-.12,0A18.56,18.56,0,0,0,177,98.91l-.16-.26-12.32-2.72-5.25-17.12-9.59-9.1-9.58,9.1-5.26,17.12-25,4a18.49,18.49,0,0,0-22.12,9.92l0,0L43.32,187.38v.35h0A14.8,14.8,0,0,0,55,211.14v0h.35a14.91,14.91,0,0,0,2.23-.19l49.65-3.61q.63,18.64,1.24,37.28l65.94-.13a175.24,175.24,0,0,1,3.21-53.89c3.11-14,7.18-23.72,9.76-33.32l15.76,35.23h0L176.33,258.3l.65.56a5.31,5.31,0,0,0-4.07,3.23l-5,11.61,14.62,6.82,5-11.61a5.92,5.92,0,0,0,0-5l34.71-61.84A11,11,0,0,0,224.51,197.65ZM80.82,185.17l2-3,21.15-31,.08,0,1.22-1.8L106.62,189Z" style={{fill: 'url(#female-torso-a)'}}/>
	</svg>
}

const Hand = props => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="368" height="370" viewBox="0 0 368 370" style={{position: 'absolute', top: -4, left: -2}}>
		<path d="M166.19,275.64a17,17,0,0,1-6.82,3,10.14,10.14,0,0,0-7.79,3.94h-4.37c-2,0-3.41-1-4.87-2l-10.22-8.88a9.81,9.81,0,0,1-2.85-10.07L113.38,250.4l11.17-14.16,13.9,13.24-.4.83a10.73,10.73,0,0,1,12.07.16l.07.05,15.53,2.87v2.46a4,4,0,0,1-4.87,3.46l-.67-.14,6.49,5.62,1.46,2.47A8.61,8.61,0,0,1,166.19,275.64Z" fill={props.skin[1]} />
	</svg>
}

const FemaleHand = props => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="66.59" height="33.18" style={{position: 'absolute', top: 188, left: 98, zIndex: 900}}>
		<path d="M164.81,216.06a2.11,2.11,0,0,0,2.41-.34c.69-.69.69-1.72-.35-2.07l-10-8.33c0-.32,0-.63-.34-.63l-3.48-2.91.73.15,6.19-2.06-15.47-5.17h0l-43.43-6.42v19.6l32.71-2.38h0a8.58,8.58,0,0,0,2.62,4.27c-.14.25-.08.45.18.45l1.72,1.37a9.61,9.61,0,0,0,2.38,1.59l9.65,8.06a2.11,2.11,0,0,0,2.41-.34c.34-.69.34-1.72-.34-2.07l-5.87-4.9a10.58,10.58,0,0,0,1.81-.5l8.53,7.12a2.09,2.09,0,0,0,2.4-.34c.69-.69.69-1.72-.34-2.07l-7.69-6.42a9.76,9.76,0,0,0,1-1l11.19,9.45a2.11,2.11,0,0,0,2.4-.35c.35-.68.35-1.72-.34-2.06l-11.17-9.43.53-.61Z" transform="translate(-101.1 -188.27)" fill={props.skin[0]}/>
	</svg>
}

const Torso = props => {
	const { top, left, skin, body, src = '/outfits/torso/1.svg' } = props 
	const skinColor = '#811708'

	return <div style={{
			position: 'absolute', 
			top: body === 'male' ? 35 : 60, 
			left: body === 'male' ? 0 : 25
		}}>
		<img style={{position: 'absolute', 
			top: body === 'male' ? -2 : -3, 
			left: body === 'male' ? 0 : 1
		}} 
		src={src} />
		{body === 'male' 
			? <TorsoBase skin={skin} />
			: <FemaleTorso skin={skin} />}
		{body === 'male' 
			? <Hand skin={skin} />
			: <FemaleHand skin={skin} /> }
	</div>
}

export default Torso