const Shoes = props => {
	const { top, left, body, src = '/outfits/shoes/1.svg' } = props 
	const skinColor = '#811708'

	return <div style={{
		position: 'absolute', 
		top: body === 'male' ? 580 : 535, 
		left: body === 'male' ? 65 : 30
	}}>
		<img style={{
			top: body === 'male' ? -2 : 0, 
			left: body === 'male' ? 0 : 0
		}} src={src} />
	</div>
}

export default Shoes