import color from 'color'

const Headgear = props => {
	const { top, left, body, src } = props 

	console.log('hg src', src)
	return <div style={{position: 'absolute', top: body === 'male' ? 0 : 0 , left: body === 'male' ? 130 : 35}}>
		<img style={{
			top: body === 'male' ? -2 : 0, 
			left: body === 'male' ? 0 : 0
		}} src={src} />
	</div>
}

export default Headgear