import React, { useState, useRef, useEffect, useContext, createContext } from 'react'

const LoaderContext = createContext({
	loaded: false,
	sources: {}
})

const imageCache = {}

export const useLoader = () => {
	return useContext(LoaderContext)
}

export const LoaderProvider = props => {
	const { sources, children } = props
	const numLoaded = useRef(0)
	const [ sourcesLoaded, setLoaded ] = useState(0)

	const onLoad = (uri, image) => {
		console.log(uri)
		setLoaded(loaded => loaded + 1)
		imageCache[uri] = image
	}

	useEffect(() => {
		sources.forEach(source => {
			const image = new Image()
			image.onload = () => onLoad(source, image)
			image.src = source
		})
	}, [])

	console.log(sourcesLoaded)

	const context = {
	  loaded: sources.length === sourcesLoaded
	}

	return <LoaderContext.Provider value={context}>
		{children}
	</LoaderContext.Provider>
}