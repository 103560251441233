const Accessories = props => {
	const { top, left, skin, body, src  } = props 
	const skinColor = '#811708'

	return <div style={{
			position: 'absolute', 
			top: body === 'male' ? 35 : 60, 
			left: body === 'male' ? 0 : 25
		}}>
		<img style={{zIndex: 999, position: 'absolute', 
			top: body === 'male' ? -2 : 0, 
			left: body === 'male' ? 0 : 1
		}} 
		src={src} />

		<img style={{zIndex: 1000, position: 'absolute', 
			top: body === 'male' ? -2 : 0, 
			left: body === 'male' ? 0 : 1
		}} 
		src={body === 'male' ? '/outfits/armband.svg' : '/outfits/female-armband.svg'} />
	</div>
}

export default Accessories