const LegsBase = props => {
	const [ skinColor, lightenedColor ] = props.skin

	return <svg xmlns="http://www.w3.org/2000/svg" width="231" height="438" viewBox="0 0 231 438">
		<defs>
			<linearGradient id="a" x1="207.08" y1="158.01" x2="207.42" y2="158.01" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color={skinColor}/>
				<stop offset="0" stop-color={lightenedColor} />
			</linearGradient>
			<linearGradient id="legs-b" x1="101.49" y1="116.39" x2="101.49" y2="365.8" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color={skinColor}/>
				<stop offset="1" stop-color={lightenedColor} />
			</linearGradient>
		</defs>
		<path d="M166.21,207.84a19.77,19.77,0,0,1-.24,8.77l-16.4,166.23L133,380.47c-1.89-23.22-3.78-44.08-4.72-55.93C125,290.89,123.57,280,123.1,262a293.78,293.78,0,0,1,3.78-49.77h0c0-.16,0-.31,0-.47a19.34,19.34,0,0,1,.26-3.06l-1.3-5.84-3.33-14.91-17-76,13.85.12,15.53.13,36.82.3Z" fill={skinColor}/>
		<path d="M134.92,112.12l-10.83,66.14-1.58,9.66-.07.46-2.69,16.41c0,.41,0,.83,0,1.25a23.1,23.1,0,0,1-.55,5L101.82,376.2l-16.55-1.89c-2.88-22.71-5.63-41.12-7.56-53.56-5-32.25-7.67-43.71-8-62.1,0-1.43,0-2.83,0-4.22a211.71,211.71,0,0,1,5-45.51A24.88,24.88,0,0,1,74.4,206a22.88,22.88,0,0,1,.21-3.11l-6.55-91.37,37.42.31h.06l13.85.12Z" style={{fill: 'url(#legs-b)'}}/>
		<polygon fill="#000" points="175.1 54.36 171.74 112.42 134.92 112.12 119.39 111.99 105.54 111.87 105.48 111.87 68.06 111.56 64 54.83 144.37 54.36 143.51 59.64 175.1 54.36"/>
	</svg>
}

const FemaleLegs = props => {
	const [ skinColor, lightenedColor ] = props.skin

	return <svg xmlns="http://www.w3.org/2000/svg" width="281.25" height="381.69" viewBox="0 0 281.25 381.69">
		<defs>
			<linearGradient id="fl-a" x1="128.34" y1="115.68" x2="140.36" y2="344.89" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color={lightenedColor}/>
				<stop offset="0.5" stop-color={skinColor}/>
			</linearGradient>
		</defs>
		<path d="M195.14,182.76a23.21,23.21,0,0,1-1.22,7.41l-7.43,105-3.32,47.06-14.5,1.23c-3.44-21-5.91-40.42-8.1-51.55C155.46,261,153,251.61,152,234.72c-.22-2.58-.39-5.1-.53-7.54a266.26,266.26,0,0,1,.43-38.16l1.72,0a23.33,23.33,0,0,1-.86-6.24c0-.22,0-.44,0-.66l-17.94-64.82h48.33l11.66,61.35,0,0A23.1,23.1,0,0,1,195.14,182.76Z" fill={skinColor}/>
		<path d="M170.3,49.67l-54.14.12.07.25L104,49.94A85.43,85.43,0,0,0,93.26,86.8a83.26,83.26,0,0,0,4.48,30.48h85.42Z" fill="#020202"/>
		<path d="M154.8,198.77h.13l-.09.69a21.19,21.19,0,0,1-.74,6.11l-2.64,21.61-7.9,64.57L137,345.06l-14.81-1.65c-2.17-20.44-4.35-36.77-5.88-47.48-.28-2.07-.54-4-.79-5.91-3.36-25.09-4.62-34.27-5.28-49.22a260.63,260.63,0,0,1,2.34-44.28h.14a21.22,21.22,0,0,1,2.54-7.12c-5.58-32.3-11.67-54.42-16.71-69.58-.25-.76-.54-1.62-.85-2.55H159.1c-.41,28-2,54.67-4.45,79.86C154.72,197.68,154.77,198.22,154.8,198.77Z" style={{fill: 'url(#fl-a)'}}/>
	</svg>
}

const Legs = props => {
	const { top, left, body, skin, src = '/outfits/legs/1.svg' } = props 
	const skinColor = '#811708'

	return <div style={{
			position: 'absolute', 
			top: body === 'male' ? 255 : 253, 
			left: body === 'male' ? 65 : 29
		}}>
		<img style={{
			position: 'absolute', 
			top: body === 'male' ? 2 : 8, 
			left: body === 'male' ? 0 : 0
		}} src={src} />
		{ body === 'male' 
			? <LegsBase skin={skin} />
			: <FemaleLegs skin={skin} /> }
	</div>
}

export default Legs