import CharacterBuilder from './components/CharacterBuilder'
import { LoaderProvider } from './utils/loader'
import { MaleHair1, MaleHair2, MaleHair3, MaleHair4 } from './components/body/Hair'
import Body from './components/body'
import color from 'color'
import SpeechBubble from './components/SpeechBubble'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useLocation
} from "react-router-dom"

const maleTorsoSources = [
  '/outfits/torso/1.svg',
  '/outfits/torso/2.svg',
  '/outfits/torso/3.svg',
  '/outfits/torso/4.svg',
  '/outfits/torso/5.svg',
  '/outfits/torso/6.svg',
  '/outfits/torso/7.svg',
  //'/outfits/torso/8.svg',
  '/outfits/torso/9.svg',
  //'/outfits/torso/10.svg',
  '/outfits/torso/11.svg',
  '/outfits/torso/12.svg',
]

const femaleTorsoSources = [
  '/outfits/female-torso/1.svg',
  '/outfits/female-torso/2.svg',
  '/outfits/female-torso/3.svg',
  '/outfits/female-torso/4.svg',
  '/outfits/female-torso/5.svg',
  '/outfits/female-torso/6.svg',
  '/outfits/female-torso/7.svg',
  //'/outfits/female-torso/8.svg'
]


const maleLegSources = [
  '/outfits/legs/1.svg',
  '/outfits/legs/2.svg',
  //'/outfits/legs/3.svg',
  '/outfits/legs/4.svg',
  '/outfits/legs/5.svg',
  '/outfits/legs/6.svg',
  '/outfits/legs/7.svg',
  '/outfits/legs/8.svg',
  '/outfits/legs/9.svg',
  '/outfits/legs/10.svg',
  '/outfits/legs/11.svg',
  //'/outfits/legs/12.svg',
]

const femaleLegSources = [
  //'/outfits/female-legs/1.svg',
  '/outfits/female-legs/2.svg',
  '/outfits/female-legs/3.svg',
  '/outfits/female-legs/4.svg',
]

const maleShoeSources = [
  '/outfits/shoes/1.svg',
  '/outfits/shoes/2.svg',
  '/outfits/shoes/3.svg',
  '/outfits/shoes/4.svg',
]

const femaleShoeSources = [
  '/outfits/female-shoes/1.svg',
  '/outfits/female-shoes/2.svg',
  '/outfits/female-shoes/3.svg',
  '/outfits/female-shoes/4.svg',
]

const maleHeadgearSources = [
  '/outfits/headgear/1.svg',
  '/outfits/headgear/2.svg',
  '/outfits/headgear/3.svg',
  '/outfits/headgear/4.svg',
]

const femaleHeadgearSources = [
  '/outfits/female-headgear/1.svg',
  '/outfits/female-headgear/2.svg',
  '/outfits/female-headgear/3.svg',
  '/outfits/female-headgear/4.svg',
]

const maleAccesssoriesSources = [
  '/outfits/accessories/1.svg',
  '/outfits/accessories/2.svg',
  '/outfits/accessories/3.svg',
  '/outfits/accessories/4.svg',
  '/outfits/accessories/5.svg',
]

const femaleAccesssoriesSources = [
  '/outfits/female-accessories/1.svg',
  '/outfits/female-accessories/2.svg',
  '/outfits/female-accessories/3.svg',
  '/outfits/female-accessories/4.svg',
  '/outfits/female-accessories/5.svg',
]

const sources = [
  ...maleTorsoSources,
  ...maleLegSources,
  ...maleShoeSources,
  ...femaleLegSources,
  ...femaleTorsoSources,
  ...femaleShoeSources,
  ...maleHeadgearSources,
  ...maleAccesssoriesSources,
  ...femaleAccesssoriesSources
]

const maleHairSources = [
  'MaleHair1',
  'MaleHair2',
  'MaleHair3',
  'MaleHair4'
]

const femaleHairSources = [
  'FemaleHair1',
  'FemaleHair2',
  'FemaleHair3',
  'FemaleHair4'
]

const hairColor = '#510402'
const hairGradient = [
    hairColor, 
    color(hairColor).darken(0.75).saturate(1).hex()
  ]

function App () {

  return <Router>
    <AppContent />
  </Router>
}

const AppContent = () => {
  const { search } = useLocation() 

  const renderBody = () => {
    const queryEntries = [...new URLSearchParams(search).entries()]
    const query = queryEntries.reduce((acc, [key, value]) => ({
      ...acc,
      [key]: value
    }), {})

    const hairGradient = [
      query.hairColor, 
      color(query.hairColor).darken(0.75).saturate(1).hex()
    ]

    console.log('um', query)

    return queryEntries.length && 
      <div style={{position: 'absolute'}}>
        <img src="/switch-squad-bg.png" />
        {<div style={{position: 'absolute', top: 150, left: 1920/2, fontWeight: 700, color: 'white', fontSize: 40, transform: 'translate(-53%)', textAlign: 'center' }}>
          {query.name
             ? <>Meet the newest memeber of the Switch Squad,<br/>
              <span style={{fontSize: 75, display: 'inline-block', marginTop: 10}}>{query.name}!</span>
             </>
             : <span style={{fontSize: 60, display: 'inline-block', marginTop: 20}}>Meet the Switch Squad!</span>
          }
        </div>}
        <Body 
          {...query}
          hairGradient={hairGradient} 
          hairColor={hairColor}
          style={{ position: 'absolute', top: query.body === 'male' ? 400 : 435, left: -55 }} />
      </div>
  }

  return <Switch>
    <Route path="/about">
    </Route>
    <Route path="/image-generator">
      {renderBody()}
    </Route>
    <Route path="/">
      <LoaderProvider sources={sources}>
        <CharacterBuilder 
          sources={{
            "male-tops": maleTorsoSources,
            "male-legs": maleLegSources,
            "male-shoes": maleShoeSources,
            "male-hair": maleHairSources,
            "male-headgear": maleHeadgearSources,
            "male-accessories": maleAccesssoriesSources,
            "female-tops": femaleTorsoSources,
            "female-legs": femaleLegSources,
            "female-shoes": femaleShoeSources,
            "female-hair": femaleHairSources,
            "female-headgear": femaleHeadgearSources,
            "female-accessories": femaleAccesssoriesSources,
          }} />
      </LoaderProvider>
    </Route>
  </Switch>
}

export default App;
