import { useState, useEffect } from 'react'
import color from 'color'
import useMeasure from 'react-use-measure'

import Head from './Head'
import Headgear from './Headgear'
import Accessories from './Accessories'
import Hair, { HairBack } from './Hair'
import Torso from './Torso'
import Legs from './Legs'
import Shoes from './Shoes'

const Body = props => {
	const { hairGradient, body, skinColor = '#811708' } = props
	const skinGradient = [
		skinColor, 
		color(skinColor).lighten(0.15).hex()
	]

	const [ ref, bounds ] = useMeasure()

	const bodyWidth = 370
	const widthPercent = Math.max(Math.min(bounds.width/ bodyWidth, 0.8), 0.25)
	const offsetX = (-(bodyWidth) * widthPercent / 2) + bounds.width / 2

	const transform = `scale(${widthPercent}) translateX(${offsetX}px)`

	return <div ref={ref} style={{ position: 'relative', width: '100%', ...(props.style || {}) }}>
		<div style={{transform}}>
			<Legs body={body} skin={skinGradient} src={props.legs} />
			<HairBack body={body} hair={hairGradient} hairSource={props.hair} />
			<Torso body={body} skin={skinGradient} src={props.tops} />
			<Head body={body} skin={skinGradient} />
			<Hair body={body} hair={hairGradient} hairSource={props.hair} />
			<Shoes body={body} src={props.shoes} />
			<Headgear body={body} src={props.headgear} />
			<Accessories body={body} src={props.accessories} />
		</div>
	</div>
}

export default Body