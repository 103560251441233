import { useRef, useEffect, useState } from 'react'
import color from 'color'

const Palette = props => {
	const { setColor } = props
	const [ loaded, setLoaded ] = useState(false)
	const [ mouseDown, setMouseDown ] = useState(false)
	const canvasRef = useRef()
	const paletteRef = useRef()

	const handleMouseMove = (evt, force) => {
		const { layerX: x, layerY: y, target } = evt.nativeEvent

		const c = target.getContext('2d');
    	const pixel = c.getImageData(x, y, 1, 1).data; 
    	const [ r, g, b, a ] = pixel

    	const isOpaque = a !== 0
    	if (isOpaque && (mouseDown || force)) {
    		const hex = color({r,g,b}).hex()
    		setColor(hex)
    	}
	}

	const mousedown = () => setMouseDown(true)
	const mouseup = () => setMouseDown(false)

	useEffect(() => {
		document.body.addEventListener('mousedown', mousedown)
		document.body.addEventListener('mouseup', mouseup)

		return () => {
			document.body.removeEventListener('mousedown', mousedown)
			document.body.removeEventListener('mouseup', mouseup)
		}	
	})

	const width = 492 * .75
    const height = 241 * .75

	useEffect(() => {
		const canvas = canvasRef.current
    	const context = canvas.getContext("2d")
		canvas.width = width
		canvas.height = height
		context.drawImage(paletteRef.current, 0, 0, 492, 241, 0, 0, width, height)
	}, [loaded])

	return <>
		<img ref={paletteRef} style={{position: 'absolute', left: -9999}} src={props.palette} onLoad={() => setLoaded(true)} alt='' />
		<canvas 
			style={{width, height}}
			onMouseDown={evt => handleMouseMove(evt, true)}
			onMouseUp={handleMouseMove}
			onMouseMove={handleMouseMove}
			ref={canvasRef} />
	</>
}

export default Palette