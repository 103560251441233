import { useEffect, useState } from 'react'
import { XF_PURPLE } from '../colors'
import { getHair, get2ndHair } from './body/Hair'

const SourceCarousel = props => {
	const { sources, type, hairGradient } = props
	const [ index, setIndex ] = useState(0)

	useEffect(() => {
		setIndex(0)
	}, [type])

	const source = [...sources, ...sources, ...sources]
	const currentSources = source.slice(index + sources.length, index + 3 + sources.length)

	const decrease = () => setIndex(
	  index => 
		index <= 0
		  ? sources.length - 1
		  : index - 1
	)

	const increase = () => setIndex(
	  index => 
		index >= (sources.length - 1)
		  ? 0
		  : index + 1
	)

	
	const jsx = type === 'hair'
	const renderHair = src => {
		const Component = getHair(src)
		const ComponentBack = get2ndHair(src)
		return <div className="img-container source" onClick={() => props.onSelect(src )}>
			<div style={{position: 'absolute', top: 10, left: 0}}>
				{ComponentBack && <ComponentBack hair={hairGradient} />}
			</div>

			<div style={{position: 'relative', top: 0, left: 0}}>
				<Component hair={hairGradient} />

			</div>
		</div>
	}

	return <div className="carousel">
		<div className="cycle" style={{left: 0}} onClick={decrease}>
			<svg xmlns="http://www.w3.org/2000/svg" width="10.2" height="19.2">
				<path fill="#fff" d="m2 10 8-9a1 1 0 0 0 0-1 1 1 0 0 0-1 0L0 9a1 1 0 0 0 0 1l9 9a1 1 0 0 0 1 0 1 1 0 0 0 0-1Z"/>
			</svg>
		</div>
		<div className="cycle" style={{right: 0}} onClick={increase}>
			<svg xmlns="http://www.w3.org/2000/svg" width="10.2" height="19.2" style={{transform: 'rotate(180deg) translate(-1px, -2px)', transformOrigin: 'center'}}>
				<path fill="#fff" d="m2 10 8-9a1 1 0 0 0 0-1 1 1 0 0 0-1 0L0 9a1 1 0 0 0 0 1l9 9a1 1 0 0 0 1 0 1 1 0 0 0 0-1Z"/>
			</svg>
		</div>

		{currentSources.map(src => jsx
			? renderHair(src)
			: <div className="img-container source" onClick={() => props.onSelect(src)}>
				<img src={src} /> 
			</div>
		)}


		<style jsx>{`
			.carousel {
				display: flex;
				position: relative;
				justify-content: center;
				align-items: center;
				padding: 0 3rem;
			}

			.img-container {
				position: relative;
				height: 150px;
		        margin-bottom: 0.75rem;
		        justify-content: center;
		        display: flex;
		        transition: transform 0.25s;
		        cursor: pointer;
		        
		       will-change: transform;
			}

			.cycle { 
			   transition: transform 0.25s;
		       cursor: pointer;
		       border-radius: 50px;
		       position: absolute;
		       background: ${XF_PURPLE};
		       width: 30px;
		       height: 30px;
		       display: flex;
		       justify-content: center;
		       align-items: center;
		       will-change: transform;
			}


			.img-container.source:hover, 
			.cycle:hover {
				transform: scale(1.1);
			}
			
			.carousel img, .carousel svg {
		        object-position: center;
		        object-fit: contain;
		        width: 100%;
		        height: 100%;
			}

			.cycle.cycle.cycle svg { 
				position: relative;
				top: -2px;
				width: 7px !important;
				height: 17px !important;
			}

		`}</style>
	</div>
}

export default SourceCarousel
