const HairGradient = props => {

	const [ hairColor, lightenedColor ] = props.hair
	return <defs>
			<linearGradient id="hair-a" x1="3369.3" y1="739.23" x2="3239.25" y2="636.61" gradientTransform="translate(-3200.67 -998.94) rotate(5.51)" gradientUnits="userSpaceOnUse">
				<stop offset="0" stop-color={lightenedColor}/>
				<stop offset="0.5" stop-color={hairColor} />
			</linearGradient>
		</defs>
}

export const MaleHair1 = props => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="120" height="127" viewBox="0 0 120 127">
		<HairGradient {...props} />
		<path d="M113.29,31.55c2.42,12.73-4.34,26.26-16.84,33a31.8,31.8,0,0,1-41-6.14c-3,4.09-11.56,6.33-16.89,7.13L31.3,77.07a18.34,18.34,0,0,1-3.25-11.7l.25-2.6C14.85,63.1,5.42,51.92,6.69,42.12c1-7.64,8.49-14.55,18.26-15.46,1.36-7.93,8-13.38,14.85-13.07,5.07.22,9.73,3.58,12.13,8.54,1-1.35,12.16-15.73,30.17-14.88C94.78,7.84,110.33,16,113.29,31.55Z" style={{fill: 'url(#hair-a)'}}/>
	</svg>
}

export const MaleHair2 = props => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="120" height="127" viewBox="0 0 120 127">
		<HairGradient {...props} />
		<path d="M90.62,41.8a33,33,0,0,1,4.46,16.64,328.46,328.46,0,0,1-44.75-2.83l-3.64-.5L47,65.43a24.88,24.88,0,0,0-8.44,4.87A25.34,25.34,0,0,0,31,82.51c-.52-1.15-10.59-24.11,2.68-42.38,2.17-3,9.66-12.31,22.63-14.31C69.78,23.76,83.83,30.38,90.62,41.8Z" style={{fill: 'url(#hair-a)'}}/>
	</svg>
}

export const MaleHair3 = props => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="120" height="127" viewBox="0 0 120 127">
		<HairGradient {...props} />
		<path d="M95.2,58.34c-7.42,0-14.82-1.68-22.24-1.72-15.27-5.53-23.71-.33-24.95.82-2.2,2.06-7.27,21.65-7.27,22.11A6.75,6.75,0,0,0,37.53,78c-1.74,0-5.64,0-6.45,5.53,0,0-2.14-25.64-3.17-31.56-4.8-3.65-3.7-8.13-1.6-13.42,1.83-4.59,6.74-5.74,11.92-6.17a17,17,0,0,1,5-7.64c7.57-6.51,18.21-3.34,19-3.09.78-.68,6.36-5.38,14-4.22A17.3,17.3,0,0,1,89.94,29c1.65.38,7.35,1.91,10.42,7C104.21,42.37,102.39,51.83,95.2,58.34Z" style={{fill: 'url(#hair-a)'}}/>
	</svg>
}

export const MaleHair4 = props => {
	return <svg xmlns="http://www.w3.org/2000/svg" width="120" height="127" viewBox="0 0 120 127">
		<HairGradient {...props} />
		<path d="M114.25,47.09A39.45,39.45,0,0,1,95.49,64.27c-20.16,8.5-39.15-5-40.28-5.8-3,4-11.31,6.28-16.54,7.1L31.62,77A55,55,0,0,0,29,71.46C23.77,61.85,19,59.8,18.89,56.08,18.8,53,21.9,48.8,35,43.52c1.55-13,5.74-17.29,9.15-18.92,4.69-2.24,7.58.78,19.7,1.09C76.13,26,77.11,23,82.77,25.07c7.54,2.75,7.68,8.74,16.91,15.35A42.93,42.93,0,0,0,114.25,47.09Z" style={{fill: 'url(#hair-a)'}}/>
	</svg>
}

export const FemaleHair1 = props => {
	const [ hairColor, lightenedColor ] = props.hair

	return <svg xmlns="http://www.w3.org/2000/svg" width="275.07" height="220.98" viewBox="0 0 275.07 220.98">
		<defs>
			<linearGradient id="fhair-a-1" x1="-2" y1="0" x2="1" y2="0">
				<stop offset="0" stop-color={lightenedColor} />
				<stop offset="1" stop-color={hairColor} />
			</linearGradient>
			<linearGradient id="fhair-b-1" x1="1" y1="1" x2="0" y2="0" gradientTransform="rotate(-7.91)">
				<stop offset="0" stop-color={lightenedColor} />
				<stop offset="1" stop-color={hairColor} />
			</linearGradient>
			<linearGradient id="fhair-c-1" x1="0" y1="2" x2="0" y2="0" gradientTransform="matrix(1, 0, 0, 1, 0, 0)">
				<stop offset="0.25" stop-color={lightenedColor} />
				<stop offset="1" stop-color={hairColor} />
			</linearGradient>
		</defs>
		<path d="M69.29,129c8.08.52,12.12-1.63,14.31-4,11.92-12.73-17.33-45.54-4.19-66.78,4.41-7.13,13.09-12.13,21.29-11.52,10.13.76,19.43,10.06,23,23.47l-12.21,5.85A18.38,18.38,0,0,0,100.88,80c-14,11.69,1.26,43.21-10,51.52C87.85,133.66,82,135,69.29,129Z" style={{fill: 'url(#fhair-a-1)'}}/>
		<path d="M215.57,114.56c-7.82,2.08-12.2.76-14.81-1.11-14.17-10.16,8.11-48-8.92-66.32C186.12,41,176.63,37.79,168.71,40c-9.78,2.72-17.09,13.67-18,27.52l13.12,3.35A18.43,18.43,0,0,1,175,72.59c16,8.74,7.19,42.62,19.91,48.57C198.28,122.72,204.26,122.89,215.57,114.56Z" style={{fill: 'url(#fhair-b-1)' }}/>
		<path d="M175.05,96.34a44.69,44.69,0,0,0-.29-5.11c-.48-4-2-17.2-12.73-26.3h0a33,33,0,0,0-4.49-3.19,31,31,0,0,0-31.34-1.12h0c-11.65,6.26-19.22,19.7-18.32,35a58.15,58.15,0,0,0,1,8.8A66.4,66.4,0,0,0,150.1,83.32c1,.67,2,1.34,3,2A110.23,110.23,0,0,0,175.05,96.34Z" style={{fill: 'url(#fhair-c-1)' }}/>
	</svg>
}

export const FemaleHair2 = props => {
	const [ hairColor, lightenedColor ] = props.hair

	return <svg xmlns="http://www.w3.org/2000/svg" width="275.07" height="220.98" viewBox="0 0 275.07 220.98">
		<defs>
			<linearGradient id="fhair-a-2" x1="-2" y1="0" x2="1" y2="0">
				<stop offset="0" stop-color={lightenedColor} />
				<stop offset="1" stop-color={hairColor} />
			</linearGradient>
			<linearGradient id="fhair-b-2" x1="1" y1="1" x2="0" y2="0" gradientTransform="rotate(-7.91)">
				<stop offset="0" stop-color={lightenedColor} />
				<stop offset="1" stop-color={hairColor} />
			</linearGradient>
		</defs>
		<ellipse cx="115.99" cy="57.48" rx="23.3" ry="23.3" transform="translate(50.72 169.27) rotate(-86.09)" style={{fill: 'url(#fhair-a-2)'}} />
		<path d="M174.08,98.9c-.07-1.86-.12-3.24-.19-5.1a37.33,37.33,0,0,0-12.17-26.31l.06-.13c-6-5.14-13.25-8.5-21.65-8.44h-.45v0h-.47c-18.51.69-32.72,17.45-32,37.83a59.27,59.27,0,0,0,.79,8.78,66.12,66.12,0,0,0,46.84-26.48A198.12,198.12,0,0,0,174.08,98.9Z" style={{fill: 'url(#fhair-b-2)'}} />
	</svg>
}

export const FemaleHair3 = props => {
	const [ hairColor, lightenedColor ] = props.hair

	return <svg xmlns="http://www.w3.org/2000/svg" width="275.07" height="220.98" viewBox="0 0 275.07 220.98">
		<defs>
			<linearGradient id="fhair-a-3" x1="-2" y1="0" x2="1" y2="0">
				<stop offset="0" stop-color={lightenedColor} />
				<stop offset="1" stop-color={hairColor} />
			</linearGradient>
		</defs>
		<path d="M157,58.87c0-.4,0-.8-.08-1.2a37.88,37.88,0,0,0-49.74,39.41,37.92,37.92,0,0,0,45.34-18,44.68,44.68,0,0,0,10.66,7.74,22.32,22.32,0,0,0,11,2.55C178.55,76.81,169.91,63.13,157,58.87Z"  style={{fill: 'url(#fhair-a-3)'}} />
	</svg>
}

export const FemaleHair3Back = props => {
	const [ hairColor, lightenedColor ] = props.hair

	return <svg xmlns="http://www.w3.org/2000/svg" width="275.07" height="220.98" viewBox="0 0 275.07 220.98">
		<defs>
			<linearGradient id="fhair-a-3a" x1="-2" y1="0" x2="1" y2="0">
				<stop offset="0" stop-color={lightenedColor} />
				<stop offset="1" stop-color={hairColor} />
			</linearGradient>
		</defs>
		<path d="M71.29,97.49c.43-2.42,3.31-17.19,17.26-25.88a38.36,38.36,0,0,1,24.73-5.34c2-2.34,11.1-12.5,25.49-13.32,16.79-1,32.46,11.27,36.9,27.09,4.29,15.29-3.53,28.21-5.06,30.63,10.74,12.35,10.3,21.44,9.84,24.73-2.9,20.85-38,34.87-64.61,29.57a54.82,54.82,0,0,1-13.25-4.46c-15.9,14.16-39.64,13.58-53.66-.1-13.87-13.54-15-36.72-3.37-50.58C54.45,99.21,67.7,97.77,71.29,97.49Z" style={{fill: 'url(#fhair-a-3a)'}}/>
	</svg>
}

export const FemaleHair4 = props => {
	const [ hairColor, lightenedColor ] = props.hair

	return <svg xmlns="http://www.w3.org/2000/svg" width="275.07" height="220.98" viewBox="0 0 275.07 220.98">
		<defs>
			<linearGradient id="fhair-b-4" x1="1" y1="1" x2="0" y2="0" gradientTransform="rotate(-7.91)">
				<stop offset="0" stop-color={lightenedColor} />
				<stop offset="1" stop-color={hairColor} />
			</linearGradient>
		</defs>
		<path d="M174.58,94.22a45.08,45.08,0,0,0-.3-5.11c-.48-4.06-2-17.22-12.74-26.32a33.66,33.66,0,0,0-15.89-7.47,32,32,0,0,0-9.9-.06c-.42,0-.84.08-1.27.14-18.18,2.36-30.65,20.16-28,40.16a57.55,57.55,0,0,0,1.58,8.58,65.39,65.39,0,0,0,39.64-24.35c1.57,1.15,3.21,2.29,4.92,3.4A109.9,109.9,0,0,0,174.58,94.22Z" style={{fill: 'url(#fhair-b-4)' }}/>
	</svg>
}


export const FemaleHair4Back = props => {
	const [ hairColor, lightenedColor ] = props.hair

	return <svg xmlns="http://www.w3.org/2000/svg" width="275.07" height="220.98" viewBox="0 0 275.07 220.98">
		<defs>
			<linearGradient id="fhair-a-4" x1="-2" y1="0" x2="1" y2="0">
				<stop offset="0" stop-color={lightenedColor} />
				<stop offset="1" stop-color={hairColor} />
			</linearGradient>
		</defs>
		<path d="M179.34,86.63a9.71,9.71,0,0,1,4.86,3.24c2.27,3.27,1.22,7.69.53,9.86a9.13,9.13,0,0,1,6.34,4.22c1.86,3.4,1,8.07-2.39,11.15a9.44,9.44,0,0,1,4.87,5.05,9.74,9.74,0,0,1-.7,8.22,11.35,11.35,0,0,1-2.58,12c-4.43,4.37-10.51,2.89-10.8,2.81a12.31,12.31,0,0,1-15.07,4,6,6,0,0,1-11.7,1.09,10.2,10.2,0,0,1-8.21,4.28,10,10,0,0,1-7.74-4.22,11.4,11.4,0,0,1-18.55-3.76,8.48,8.48,0,0,1-16.43-.47A7.9,7.9,0,0,1,89.92,135a8.1,8.1,0,0,1,1-16,8.36,8.36,0,0,1,2.82-16.2,8.41,8.41,0,0,1,9-13.83C107.69,88.56,169.82,83.64,179.34,86.63Z" style={{fill: 'url(#fhair-a-4)' }}/>
	</svg>
}




export const getHair = hair => {
	switch (hair) {
		case 'MaleHair1': return MaleHair1
		case 'MaleHair2': return MaleHair2
		case 'MaleHair3': return MaleHair3
		case 'MaleHair4': return MaleHair4
		case 'FemaleHair1': return FemaleHair1
		case 'FemaleHair2': return FemaleHair2
		case 'FemaleHair3': return FemaleHair3
		case 'FemaleHair4': return FemaleHair4
	}
}

export const get2ndHair = hair => {
	switch (hair) {
		case 'FemaleHair3': return FemaleHair3Back
		case 'FemaleHair4': return FemaleHair4Back
		default: return false
	}
}

const Hair = props => {
	const { top, left, body, hair, hairSource = 'MaleHair1' } = props 

	const HairComponent = getHair(hairSource)
	return <div style={{
			position: 'absolute', 
			top: body === 'male' ? 0 : 0, 
			left: body === 'male' ? 130 : 35
		}}>
		<HairComponent hair={hair}/>
	</div>
}

export const HairBack = props => {
	const { top, left, body, hair, hairSource = 'MaleHair1' } = props 

	const HairComponent = get2ndHair(hairSource)
	return HairComponent && <div style={{
			position: 'absolute', 
			top: body === 'male' ? 0 : 0, 
			left: body === 'male' ? 130 : 35
		}}>
		<HairComponent hair={hair}/>
	</div>
}

export default Hair