import { XF_PURPLE } from '../colors'

const SpeechPointer = props =>
	<svg style={props.style} width="40" height="23" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146.6 98.6">
		<path d="M0 0s23 84 104 99l43-58S59 41 0 0Z" fill={XF_PURPLE} />
	</svg> 

const SpeechBubble = props => 
	<div onClick={props.onClick} style={{ cursor: 'pointer', position: 'absolute', background: XF_PURPLE, borderRadius: 50, minWidth: 200, minHeight: 100, left: 10, padding: 40, color: 'white', zIndex: 50, textAlign: 'center', ...props.style}}>
		<SpeechPointer style={{left: -10, top: 0, width: 40, height: 23, position: 'absolute', zIndex: -1}} />
		<div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', top: -5, right: -5, borderRadius: 100, position: 'absolute', padding: '20px 20px 18px 20px', width: 40, height: 40, background: XF_PURPLE, border: '2px solid white'}}>X</div>
		{props.children}
	</div>



export default SpeechBubble
